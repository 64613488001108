import React from 'react';
import './Connectors.css';
import EvStationIcon from '@mui/icons-material/EvStation';
import { Link } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Connectors = props => {

    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (props.items.length === 0) {
        return <div>Nincs adat.</div>;
    }

    const statusHun = (status) => {
        const statusMap = {
            'AVAILABLE': 'ELÉRHETŐ',
            'BLOCKED': 'NEM ELÉRHETŐ',
            'CHARGING': 'FOGLALT',
            'INOPERATIVE': 'NEM MŰKÖDIK',
            'OUTOFORDER': 'NEM MŰKÖDIK',
            'PLANNED': 'FOGLALT',
            'REMOVED': 'NEM MŰKÖDIK',
            'RESERVED': 'FOGLALT'
        };
        return statusMap[status] || status; // Ha nincs leképezve, visszaadja az eredeti státuszt
    };

    const statusClasses = {
        'AVAILABLE': 'status-available',
        'BLOCKED': 'status-blocked',
        'CHARGING': 'status-charging',
        'INOPERATIVE': 'status-inoperative',
        'OUTOFORDER': 'status-outoforder',
        'PLANNED': 'status-planned',
        'REMOVED': 'status-removed',
        'RESERVED': 'status-reserved'
    };

    const getLink = (link) => {
        const links = {
            '4069' : 'NDA2OQ%253D%253D',
            '4087' : 'NDA4Nw%253D%253D',
            '4071' : 'NDA3MQ%253D%253D',
            '4079' : 'NDA3OQ%253D%253D',
            '4073' : 'NDA3Mw%253D%253D',
            '4081' : 'NDA4MQ%253D%253D',
            '4075' : 'NDA3NQ%253D%253D',
            '4083' : 'NDA4Mw%253D%253D',
            '4077' : 'NDA3Nw%253D%253D',
            '4085' : 'NDA4NQ%253D%253D',
            '4070' : 'NDA3MA%253D%253D',
            '4088' : 'NDA4OA%253D%253D',
            '4072' : 'NDA3Mg%253D%253D',
            '4080' : 'NDA4MA%253D%253D',
            '4074' : 'NDA3NA%253D%253D',
            '4082' : 'NDA4Mg%253D%253D',
            '4076' : 'NDA3Ng%253D%253D',
            '4084' : 'NDA4NA%253D%253D',
            '4078' : 'NDA3OA%253D%253D',
            '4086' : 'NDA4Ng%253D%253D'
        };
        return links[link] || '';
    };

    const filterByDirection = (directionPrefix) => {
        return props.items.data.evses.filter(data => 
            data.directions.length > 0 && data.directions[0].text.startsWith(directionPrefix)
        );
    };

    const p1Chargers = filterByDirection('P-1');
    const p2Chargers = filterByDirection('P-2');
    //const p3Chargers = filterByDirection('P-3');
    const p4Chargers = filterByDirection('P-4');

    return (
        <React.Fragment>

    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fill: '#FFF' }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ backgroundColor: '#002854',color: '#FFF',textTransform: 'uppercase' }}
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontFamily:'avenir_heavy' }}>P-1 szint</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className='mg-charger-content'>
                {p1Chargers.map((data, index) => (
                    <div key={'mg-charger-box' + data.uid} className='mg-charger-box'>
                        <Link key={'link' + data.uid} to={`https://e-toltesdebrecen.hu/site/toltokivalasztas?cs=${getLink(data.physical_reference)}`} target="_blank" style={{textDecoration:'none',color:'unset'}}>
                            <div key={'mg-charger-box-header' + data.uid} className='mg-charger-box-header'>{data.physical_reference}</div>
                            <div key={'mg-charger-box-body' + data.uid} className='mg-charger-box-body'>
                                <EvStationIcon key={'EvStationIcon' + data.uid} style={{fontSize:'30px',marginBottom:'5px'}}/>
                                <div key={'power' + data.uid}>22kW / 3x32A</div>
                                <div key={'type' + data.uid}>Type 2</div>
                                <div key={'desc' + data.uid} className='mg-desc1'>{data.directions[0].text}</div>
                            </div>
                            <div key={'mg-charger-box-footer' + data.uid} className='mg-charger-box-footer'>
                                <div key={'mg-charger-status' + data.uid} className={`mg-charger-status ${statusClasses[data.status] || ''}`}>{statusHun(data.status)}</div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ marginTop:'4px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fill: '#FFF' }} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          sx={{ backgroundColor: '#002854',color: '#FFF',textTransform: 'uppercase' }}
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontFamily:'avenir_heavy'}}>P-2 szint</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className='mg-charger-content'>
                {p2Chargers.map((data, index) => (
                    <div key={'mg-charger-box' + data.uid} className='mg-charger-box'>
                        <Link key={'link' + data.uid} to={`https://e-toltesdebrecen.hu/site/toltokivalasztas?cs=${getLink(data.physical_reference)}`} target="_blank" style={{textDecoration:'none',color:'unset'}}>
                            <div key={'mg-charger-box-header' + data.uid} className='mg-charger-box-header'>{data.physical_reference}</div>
                            <div key={'mg-charger-box-body' + data.uid} className='mg-charger-box-body'>
                                <EvStationIcon key={'EvStationIcon' + data.uid} style={{fontSize:'30px',marginBottom:'5px'}}/>
                                <div key={'power' + data.uid}>22kW / 3x32A</div>
                                <div key={'type' + data.uid}>Type 2</div>
                                <div key={'desc' + data.uid} className='mg-desc1'>{data.directions[0].text}</div>
                            </div>
                            <div key={'mg-charger-box-footer' + data.uid} className='mg-charger-box-footer'>
                                <div key={'mg-charger-status' + data.uid} className={`mg-charger-status ${statusClasses[data.status] || ''}`}>{statusHun(data.status)}</div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </AccordionDetails>
      </Accordion>

      <Accordion disabled sx={{ width: '100%', marginTop:'5px' }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
        >
        <Typography sx={{ width: '33%', flexShrink: 0, fontFamily:'avenir_heavy', textTransform: 'uppercase' }}>
        P-3 szint
        </Typography>
        <Typography sx={{ color: 'text.secondary', fontFamily:'avenir_heavy' }}>
            Nincs üzemben elektromos töltőállomás
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
</AccordionDetails>
    </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ marginTop:'5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fill: '#FFF' }} />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          sx={{ backgroundColor: '#002854',color: '#FFF',textTransform: 'uppercase' }}
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontFamily:'avenir_heavy' }}>
            P-4 szint
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className='mg-charger-content'>
                {p4Chargers.map((data, index) => (
                    <div key={'mg-charger-box' + data.uid} className='mg-charger-box'>
                        <Link key={'link' + data.uid} to={`https://e-toltesdebrecen.hu/site/toltokivalasztas?cs=${getLink(data.physical_reference)}`} target="_blank" style={{textDecoration:'none',color:'unset'}}>
                            <div key={'mg-charger-box-header' + data.uid} className='mg-charger-box-header'>{data.physical_reference}</div>
                            <div key={'mg-charger-box-body' + data.uid} className='mg-charger-box-body'>
                                <EvStationIcon key={'EvStationIcon' + data.uid} style={{fontSize:'30px',marginBottom:'5px'}}/>
                                <div key={'power' + data.uid}>22kW / 3x32A</div>
                                <div key={'type' + data.uid}>Type 2</div>
                                <div key={'desc' + data.uid} className='mg-desc1'>{data.directions[0].text}</div>
                            </div>
                            <div key={'mg-charger-box-footer' + data.uid} className='mg-charger-box-footer'>
                                <div key={'mg-charger-status' + data.uid} className={`mg-charger-status ${statusClasses[data.status] || ''}`}>{statusHun(data.status)}</div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </AccordionDetails>
      </Accordion>












        </React.Fragment>
    );
}

export default Connectors
