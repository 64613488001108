import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from '../components/LoadingSpinner';
import Connectors from '../components/Connectors';
import MapMg from '../components/MapMg';
import './Sas.css';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Matterport from '../images/matterport.jpg';
import { Link } from "react-router-dom";

const Sas = () => {

    const [loadedCharger, setLoadedCharger] = useState();
    const [loadedOccupancy, setLoadedOccupancy] = useState();
    const [loadedTime, setLoadedTime] = useState();
    const { isLoading, sendRequest } = useHttpClient();
    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "../photos/sas/DSC_0008.JPG", width: 1920, height: 1080 },
        { src: "../photos/sas/DSC_0021.JPG", width: 1920, height: 1080 },
        { src: "../photos/sas/DSC_0025.JPG", width: 1920, height: 1080 }
      ];

    const mapData = { 
        'name' : 'Sas utcai mélygarázs',
        'address' : '4024 Debrecen, Sas u. 1.',
        'lng' : 21.627162602878098,
        'lat' : 47.52936504424121
    }

    useEffect(() => {
        const fetchRequest = async () => {
            const options = {
                url:  `./ocpi_data.php`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedCharger(responseData);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest]);

        useEffect(() => {
            const fetchRequest = async () => {
                const options = {
                    url:  `./occupancy_data.php`,
                    method: 'GET',
                    body: null,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
                try {
                const responseOccupancyData = await sendRequest(
                    options.url,
                    options.method,
                    options.body,
                    options.headers
                );
                setLoadedOccupancy(responseOccupancyData);
                } catch (err) {}
            };
            fetchRequest();
            }, [sendRequest]);

            useEffect(() => {
                const fetchRequest = async () => {
                    const options = {
                        url:  `./get_date.php`,
                        method: 'GET',
                        body: null,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    }
                    try {
                    const responseData = await sendRequest(
                        options.url,
                        options.method,
                        options.body,
                        options.headers
                    );
                    setLoadedTime(responseData);
                    } catch (err) {}
                };
                fetchRequest();
                }, [sendRequest]);
                
    return (
        <React.Fragment>
            <div className="mg-orange"></div>
            <div className="box-title">{mapData.name}</div>
            <div className='mg-box'>
                <div className='mg-left-box'>
                    <div className='mg-general'>
                        <div className="mg-title">Információk</div>
                        <div>{mapData.address}</div>
                        <div>Szabad férőhelyek: {loadedOccupancy && (loadedOccupancy.areas[4].capacity - loadedOccupancy.areas[4].occupied)} / {loadedOccupancy && loadedOccupancy.areas[4].capacity} (frissítve: {loadedTime && loadedTime.lastModified})</div>
                        <div>Parkolási tarifa: 480Ft/óra</div>
                        <div>Elektromos töltés tarifája: 170Ft/kW 4Ft/perc</div>
                        <div>Fizetési lehetőségek: QR kódos online, Rollet, Bankkártya, Készpénz </div>
                        <div>Magasság korlátozás: 2,1m</div>
                    </div>
                    <div className='mg-chargers'>
                        <div className='mg-title'>
                            <div>Elektromos töltők</div>
                            <div>Frissítve: {loadedTime && loadedTime.lastModified}</div>
                        </div>
                        {isLoading && (
                            <div className="center">
                                <LoadingSpinner asOverlay />
                            </div>
                        )}
                        {!isLoading && loadedCharger && (
                            <Connectors items={loadedCharger} />
                        )}
                    </div>
                </div>
                <div className='mg-right-box'>
                    
                    <div className='mg-photos'>
                      
                      <div class='ddd'>
                        <div class='ddd-link'><Link to='https://my.matterport.com/show/?m=QxR16hm2Hjg' target='_blank'><img src={Matterport} alt='3D nézet'/></Link></div>
                        <div class='ddd-text'>Kattintson a linkre, ezután számítógépen a billentyűzet nyilaival, okostelefonon a kijelző érintésével sétálhat a modellben. A bal alsó sarokban található ikonokra kattintva a nézetek között válthat: babaház nézet, alaprajz nézet és belső séta. </div>
                      </div>
                      <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                      <Lightbox
                          slides={photos}
                          open={index >= 0}
                          index={index}
                          close={() => setIndex(-1)}
                          // enable optional lightbox plugins
                          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                      />
                    </div>
                <div className='mg-desc'>
                    <div className='mg-title'>Megközelíthetőség</div>
                    <div className='mg-text'>A Sas utcai mélygarázs a régi városháza épülete mögött található. A be-, és a kihajtás egy irányból, a Kossuth utca felől történhet, melyet jelzőlámpa irányít. Mivel a Kossuth utca egyirányú, azt megközelíteni csak a Piac és a Széchenyi utca felől lehet. A mélygarázsba be-, és kihajtani is csak balra kanyarodva tudnak az autósok.</div>
                </div>
                <div className='mg-map'>
                    <MapMg mapData={mapData}/>
                </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Sas;