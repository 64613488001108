import React from 'react';

const General = () => {

    return (
        <React.Fragment>
            <div className="mg-orange"></div>
            <div className="box-title">Általános információk</div>
            <div>
                <p>Debrecenben hat mélygarázst üzemeltet a DV Parking Kft. A mélygarázsok zárt kameraláncú biztonsági felügyelettel vannak ellátva, ennek köszönhetően nagyobb biztonságot nyújtanak, mint a felszíni parkolók. További előnye, hogy védelmet nyújt a természeti hatásokkal szemben is, valamint minden esetben csak a valós parkolási idő után kell megfizetni a díjakat.</p>

                <p>A garázsok sorompóval vannak ellátva, behajtáskor egyszeri alkalomra szóló kártyát kap az autós, melyet a parkolás végén az automatánál fizet ki. Ezt követően, a kihajtáskor a sorompó kiengedi a járművet. A mélygarázsokban működik a mobilfizetés, és a ROLLET fizetési rendszer is.</p>

                <p>A garázsok belső területeinek magassága több helyen eltér a bejáratnál jelzettektől, ezért a garázsban történő közlekedésnél ezt figyelembe kell venni. A járművel illetve a hozzákapcsolt utánfutóval, pótkocsival a garázsba csak a diszpécserrel történt előzetes egyeztetést követően, annak engedélyével és útmutatása szerint lehet lehajtani.</p>

                <p>Gazdálkodó szervezetek és más jogi személyek, vagy magánszemélyek részére szóló teljes értékű, mindhárom övezetben érvényes felszíni bérlet mellé megvásárolt Garázs+ bérlettel, vagy az adott mélygarázshoz vásárolható bérlettel rendelkezőket, a parkoló-rendszer automatikusan be-, illetve kiengedi.</p>

            </div>
        </React.Fragment>
      );

}

export default General;