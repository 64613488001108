import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import MainPage from './pages/MainPage';
import Sas from './pages/Sas';
import Halkoz from './pages/Halkoz';
import Bethlen from './pages/Bethlen';
import Kolcsey from './pages/Kolcsey';
import Itelotabla from './pages/Itelotabla';
import Nagyerdei from './pages/Nagyerdei';
import General from './pages/General';
import Etoltes from './pages/Etoltes';
import Qrkod from './pages/Qrkod';
import RolletApp from './pages/RolletApp';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="*" element={<MainPage />} />
        <Route path="/sas-utcai-melygarazs" element={<Sas />} />
        <Route path="/halkoz-melygarazs" element={<Halkoz />} />
        <Route path="/bethlen-utcai-melygarazs" element={<Bethlen />} />
        <Route path="/kolcsey-kozpont-melygarazs" element={<Kolcsey />} />
        <Route path="/itelotabla-melygarazs" element={<Itelotabla />} />
        <Route path="/nagyerdei-melygarazs" element={<Nagyerdei />} />
        <Route path="/altalanos-informaciok" element={<General />} />
        <Route path="/e-toltes" element={<Etoltes />} />
        <Route path="/qr-kodos-fizetes" element={<Qrkod />} />
        <Route path="/rollet-applikacio" element={<RolletApp />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
