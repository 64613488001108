import React from 'react';

const Qrkod = () => {

    return (
        <React.Fragment>
            <div className="mg-orange"></div>
            <div className="box-title">QR-kódos fizetés</div>
            <div>
                <p>A Sas utcai mélygarázsban a behajtáskor húzott jegyen található QR kód beolvasásával, gyors és egyszerű a parkolási díj kifizetése. Kihajtás előtt, a SIMPLE Pay rendszerén keresztül, még autójában ülve fizethet, a számlát pedig azonnal megkapja a megadott e-mail címre. Mindehhez teljes WIFI lefedettséget biztosít az üzemeltető DV Parking Kft. </p>
                <p>Fizetés után, kihajtáskor a sorompó azonnal nyílik, a rendszer kiengedi a mélygarázsból.</p>
            </div>
        </React.Fragment>
      );

}

export default Qrkod;