import React, { useEffect } from 'react';
import { loadModules } from 'esri-loader';
import marker from '../images/map-marker.svg';

const MapComponent = () => {
  useEffect(() => {
    const loadModulesPromise = loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/PopupTemplate',
    ], { css: true });

    loadModulesPromise.then(([Map, MapView, Graphic, GraphicsLayer, PopupTemplate]) => {
      const map = new Map({
        basemap: 'osm',
      });

      const view = new MapView({
        container: 'mapView',
        map: map,
        center: [21.62584723743506, 47.53186935817382],
        zoom: 13,
      });

      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      const points = [
        { longitude: 21.627162602878098, latitude: 47.52936504424121, name: 'Sas utcai mélygarázs', description: '4024 Debrecen, Sas u. 1.' },
        { longitude: 21.6231732152905, latitude: 47.5291136400494, name: 'Halköz mélygarázs', description: '4025 Debrecen, Simonffy u. 4–6.' },
        { longitude: 21.620543420004115, latitude: 47.532098386660415, name: 'Bethlen utcai mélygarázs', description: '4026 Debrecen, Bethlen u. 10.' },
        { longitude: 21.621393239256935, latitude: 47.53340346905552, name: 'Kölcsey központ mélygarázs', description: '4026 Debrecen, Hunyadi utca 1-3.' },
        { longitude: 21.62188036876933, latitude: 47.5274298491278, name: 'Ítélőtábla mélygarázs', description: '4025 Debrecen, Széchenyi u. 32.' },
        { longitude: 21.630881146280757, latitude: 47.55219335573442, name: 'Nagyerdei mélygarázs', description: '4032 Debrecen, Nagyerdei Park' }
      ];

      const svgMarker = {
        type: 'picture-marker',
        url: marker,
        width: '36px',
        height: '36px',
        yoffset: '18px'
      };

      points.forEach((point) => {
        const pointGeometry = {
          type: 'point',
          longitude: point.longitude,
          latitude: point.latitude,
        };

        const pointGraphic = new Graphic({
          geometry: pointGeometry,
          symbol: svgMarker,
          attributes: {
            name: point.name,
            description: point.description,
          },
        });

        graphicsLayer.add(pointGraphic);
      });

      // Kattintás eseménykezelő hozzáadása a markerhez
      view.on('click', (event) => {
        view.hitTest(event).then((response) => {
          if (response.results.length > 0) {
            const graphic = response.results[0].graphic;
            const attributes = graphic.attributes;

            // Buborék ablak tartalmának beállítása
            const popupTemplate = new PopupTemplate({
              title: attributes.name,
              content: attributes.description,
            });

            graphic.popupTemplate = popupTemplate;

            // Buborék ablak megjelenítése
            view.popup.open({
              features: [graphic],
              location: event.mapPoint,
            });
          }
        });
      });

      // Cleanup when the component unmounts
      return () => {
        if (view) {
          view.container = null;
        }
      };
    });
  }, []);

  return <div id="mapView" style={{ height: '100%',minHeight:'400px' }}></div>;
};

export default MapComponent;
