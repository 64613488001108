import React, { useState } from 'react';
import MapMg from '../components/MapMg';
import './Sas.css';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Nagyerdei = () => {

    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "../photos/nagyerdei/nagyerdei_001.jpg", width: 1920, height: 1280 },
        { src: "../photos/nagyerdei/nagyerdei_002.jpg", width: 1920, height: 1280 },
        { src: "../photos/nagyerdei/nagyerdei_003.jpg", width: 1920, height: 1280 },
        { src: "../photos/nagyerdei/nagyerdei_004.jpg", width: 1920, height: 1280 },
        { src: "../photos/nagyerdei/nagyerdei_005.jpg", width: 1920, height: 1280 },
        { src: "../photos/nagyerdei/nagyerdei_006.jpg", width: 5502, height: 1280 }
      ];

    const mapData = { 
        'name' : 'Nagyerdei mélygarázs',
        'address' : '4032 Debrecen, Nagyerdei Park',
        'lng' : 21.630881146280757,
        'lat' : 47.55219335573442
    }

    return (
        <React.Fragment>
            <div className="mg-orange"></div>
            <div className="box-title">{mapData.name}</div>
            <div className='mg-box'>
                <div className='mg-left-box'>
                    <div className='mg-general'>
                        <div className="mg-title">Információk</div>
                        <div>{mapData.address}</div>
                        <div>Férőhelyek: 154</div>
                        <div>Parkolási tarifa: 480Ft/óra</div>
                        <div>Fizetési lehetőségek: Rollet, Bankkártya, Készpénz </div>
                        <div>Magasság korlátozás: 2,1m</div>
                    </div>
                    <div className='mg-desc'>
                    <div className='mg-title'>Megközelíthetőség</div>
                    <div className='mg-text'>A Nagyerdei mélygarázs a Ködszínház alatt található. A be-, és a kihajtás két irányból történik, a Pallagi és az Ady Endre utca felől. Az autósok, követve a KRESZ szabályait, be-, és kihajtásra is használhatják a mélygarázs mindkét bejáratát.</div>
                </div>
                <div className='mg-map'>
                    <MapMg mapData={mapData}/>
                </div>
                </div>
                <div className='mg-right-box'>
                    
                    <div className='mg-photos'>
                      
                      <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                      <Lightbox
                          slides={photos}
                          open={index >= 0}
                          index={index}
                          close={() => setIndex(-1)}
                          // enable optional lightbox plugins
                          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                      />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Nagyerdei;