import React from 'react';

const General = () => {

    return (
        <React.Fragment>
            <div className="mg-orange"></div>
            <div className="box-title">e-TÖLTÉS</div>
            <div>
                <p>Debrecenben, a DV Parking Kft. összesen 19 töltőállomást üzemeltet. A SAS utcai mélygarázsban 10, a felszíni parkolókban 9 töltőállomás van kihelyezve, melyeken darabonként 2-2 töltőfej található.</p>
                <p>A töltésért a felvett KWh, és a töltőn eltöltött idő alapján kell fizetni. A két tétel összege adja meg a fizetendő díjat.</p>

                <p>A töltőn eltöltött idő maximum 3 óra lehet. A limitidőt követően 15 perc áll az autósok rendelkezésére, hogy elhagyják a parkolóhelyet. Amennyiben ezt nem teszik meg a megadott időre, túlhasználati díjat kell fizetniük.</p>

                <h3>Mobil applikáció</h3>

                <p>A fizetés egy erre a célra kifejlesztett mobil applikáción keresztül történik, melyhez minden töltőállomást egyedi azonosítóval láttak el. A töltőfej fölött található QR-kód beolvasása után, a mobilapp lépésről-lépésre segítséget nyújt az autósnak a töltőberendezés használatához. A rendszer folyamatosan, e-mail üzenetek formájában ad tájékoztatást a töltés állapotáról, a megadott e-mail címre.</p>

                <p>Az adatok megadását követően, a maximális töltési időnek megfelelő összeget, illetve a túlhasználati díj összegét is zárolja a bank. A limitidőn belüli töltés végén, azonban csak a tényleges használat után kell fizetni.</p>

                <p>A töltést az e-toltesdebrecen.hu, az e-toltesdvparking.hu és a dvparking.hu oldalról is el tudják indítani az elektromos autók tulajdonosai.</p>

            </div>
        </React.Fragment>
      );

}

export default General;