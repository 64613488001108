import React, { useEffect } from 'react';
import { loadModules } from 'esri-loader';
import marker from '../images/map-marker.svg';

const MapMg = props => {
  useEffect(() => {
    const loadModulesPromise = loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/PopupTemplate',
    ], { css: true });

    loadModulesPromise.then(([Map, MapView, Graphic, GraphicsLayer, PopupTemplate]) => {
      const map = new Map({
        basemap: 'osm',
      });

      const view = new MapView({
        container: 'mapView',
        map: map,
        center: [props.mapData.lng, props.mapData.lat],
        zoom: 18,
      });

      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      const points = [
        { longitude: props.mapData.lng, latitude: props.mapData.lat, name: props.mapData.name, description: props.mapData.address }
      ];

      const svgMarker = {
        type: 'picture-marker',
        url: marker,
        width: '36px',
        height: '36px',
        yoffset: '18px'
      };

      points.forEach((point) => {
        const pointGeometry = {
          type: 'point',
          longitude: point.longitude,
          latitude: point.latitude,
        };

        const pointGraphic = new Graphic({
          geometry: pointGeometry,
          symbol: svgMarker,
          attributes: {
            name: point.name,
            description: point.description,
          },
        });

        graphicsLayer.add(pointGraphic);
      });

      // Kattintás eseménykezelő hozzáadása a markerhez
      view.on('click', (event) => {
        view.hitTest(event).then((response) => {
          if (response.results.length > 0) {
            const graphic = response.results[0].graphic;
            const attributes = graphic.attributes;

            // Buborék ablak tartalmának beállítása
            const popupTemplate = new PopupTemplate({
              title: attributes.name,
              content: attributes.description,
            });

            graphic.popupTemplate = popupTemplate;

            // Buborék ablak megjelenítése
            view.popup.open({
              features: [graphic],
              location: event.mapPoint,
            });
          }
        });
      });

      // Cleanup when the component unmounts
      return () => {
        if (view) {
          view.container = null;
        }
      };
    });
  }, [props.mapData.address,props.mapData.lat,props.mapData.lng,props.mapData.name]);

  return <div id="mapView" style={{ height: '100%' }}></div>;
};

export default MapMg;
