import React from 'react';
//import pic1 from '../photos/map-marker.svg';

const RolletApp = () => {

    return (
        <React.Fragment>
            <div className="mg-orange"></div>
            <div className="box-title">Rollet applikáció</div>
            <div>
                <p>A Rollet parkolási rendszerrel, már 7 helyen parkolhatnak az autósok Debrecenben, parkolójegy húzása nélkül. A parkolóba való behajtáskor a parkolás elindítását, a Rollet automatikusan intézi.  Az autósnak csak a behajtás és kihajtás között eltelt időtartamért kell fizetnie. Az alkalmazás ingyenes, a fizetés automatikus. Elérhető Android és iOS készülékekre is.</p>
                <p>Az alkalmazás regisztrációhoz kötött, első alkalommal rögzítenie kell személyes adatait, járműve rendszámát és a fizetéshez használandó bankkártya adatait. A regisztráció ingyenes, azonban egy egyszeri 1900 Ft-os kaució szükséges, így garantálva a bankkártyás fizetés gyorsaságát és a parkolás gördülékenységét. A kauciót visszatérítik, amennyiben úgy dönt, nem kívánja tovább használni a Rollet fizetést.</p>
                <p>Az alkalmazás mindig mutatja az Önhöz legközelebb eső parkolóhelyeket és ezekhez egy gombnyomásra megtervezheti az útvonalat.</p>
                <p>A parkolóba való behajtáskor a Rollet felismeri járművét és a sorompó felnyitásával egyidejűleg elindítja parkolását. Erről minden esetben push értesítést küld Önnek. Az alkalmazásban bármikor nyomon követheti parkolásának aktuális óraállását.</p>
                <p>Nincs parkolási limit, a felhasználó a tényleges parkolási időért fizet. A kihajtás pillanatában a Rollet automatikusan levonja rögzített bankkártyáról  a parkolási díjat.</p>
                <p>A Rollet alkalmazásban  ÁFÁ-s számlát is igényelhet, melyet minden parkolás végén elektronikusan kap meg a felhasználó.</p>
                <p>A Rolletet nem csak magánszemélyek, hanem cégek is használhatják! Egy Rollet fiókhoz több jármű is rögzíthető, így könnyen rendezhető a vállalkozás autóinak parkoltatása egy regisztrált fiókkal, bankkártyával is. A Rollet több jármű esetén sem számít fel kényelmi díjat, így mindig csak a tényleges parkolási idő után kell fizetni.</p>
            </div>
        </React.Fragment>
      );

}

export default RolletApp;