import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from '../components/LoadingSpinner';
import './MainPage.css';
import Banners from '../components/Banners';
import Map from '../components/Map';
import { Link } from "react-router-dom";
import { ReactComponent as RolletLogo } from '../images/rollet.svg';
import QrCodeIcon from '@mui/icons-material/QrCode';
import EvStationIcon from '@mui/icons-material/EvStation';
import InfoIcon from '@mui/icons-material/Info';

const MainPage = () => {
    const [loadedCharger, setLoadedCharger] = useState();
    const { isLoading, sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchRequest = async () => {
            const options = {
                url:  `./ocpi_data.php`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedCharger(responseData);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest]);

    return(
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            {!isLoading && loadedCharger && (
              <Banners items={loadedCharger} />
            )}
            <div className='app-content'>
                <div className='content-col1'>

                    <div className='content-col4'>
                        <Link to='./rollet-applikacio' className='title-link'>
                            <div className="box-title-mg">
                                <RolletLogo className='title-logo-own' />
                                <h3>Rollet applikáció</h3>
                            </div>
                        </Link>
                        <p>A Rollet parkolási rendszerrel, már 6 helyen parkolhatnak az autósok Debrecenben, parkolójegy húzása nélkül. A parkolóba való behajtáskor a parkolás elindítását, a Rollet automatikusan intézi. Az autósnak csak a behajtás és kihajtás között eltelt időtartamért kell fizetnie. Az alkalmazás ingyenes, a fizetés automatikus. Elérhető Android és iOS készülékekre is.</p>
                    </div>

                    <div className='content-col4'>
                        <Link to='./qr-kodos-fizetes' className='title-link'>
                            <div className="box-title-mg">
                                <QrCodeIcon className='title-logo' style={{ fontSize: 50 }}/>
                                <h3>QR-kódos fizetés</h3>
                            </div>
                        </Link>
                        <p>A Sas utcai mélygarázsban a behajtáskor húzott jegyen található QR kód beolvasásával, gyors és egyszerű a parkolási díj kifizetése. Kihajtás előtt, a SIMPLE Pay rendszerén keresztül, még autójában ülve fizethet, a számlát pedig azonnal megkapja a megadott e-mail címre. Mindehhez teljes WIFI lefedettséget biztosít az üzemeltető DV Parking Kft.</p>
                    </div>

                </div>

                <div className='content-col2'>

                    <div className='content-col4'>
                        <Link to='e-toltes' className='title-link'>
                            <div className="box-title-mg">
                                <EvStationIcon className='title-logo' style={{ fontSize: 50 }}/>
                                <h3>e-TÖLTÉS</h3>
                            </div>
                        </Link>
                        <p>Debrecenben, a DV Parking Kft. összesen 19 töltőállomást üzemeltet. A SAS utcai mélygarázsban 10, a felszíni parkolókban 9 töltőállomás van kihelyezve, melyeken darabonként 2-2 töltőfej található. A töltésért a felvett KWh, és a töltőn eltöltött idő alapján kell fizetni. A két tétel összege adja meg a fizetendő díjat. </p>
                    </div>

                    <div className='content-col4'>
                        <Link to='./altalanos-informaciok' className='title-link'>
                            <div className="box-title-mg">
                                <InfoIcon className='title-logo' style={{ fontSize: 50 }}/>
                                <h3>Általános információk</h3>
                            </div>
                        </Link>
                        <p>Debrecenben hat mélygarázst üzemeltet a DV Parking Kft. A mélygarázsok zárt kameraláncú biztonsági felügyelettel vannak ellátva, ennek köszönhetően nagyobb biztonságot nyújtanak, mint a felszíni parkolók. További előnye, hogy védelmet nyújt a természeti hatásokkal szemben is, valamint minden esetben csak a valós parkolási idő után kell megfizetni a díjakat.</p>
                    </div>
                </div>
                <div className='content-col3'>
                    <Map />
                </div>
            </div>
        </React.Fragment>
    );
}
export default MainPage;