import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from '../components/LoadingSpinner';
import MapMg from '../components/MapMg';
import './Sas.css';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Bethlen = () => {

    const [loadedOccupancy, setLoadedOccupancy] = useState();
    const [loadedTime, setLoadedTime] = useState();
    const { isLoading, sendRequest } = useHttpClient();
    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "../photos/bethlen/bethlen_001.jpg", width: 1920, height: 1280 },
        { src: "../photos/bethlen/bethlen_002.jpg", width: 1920, height: 1280 },
        { src: "../photos/bethlen/bethlen_003.jpg", width: 1920, height: 1280 },
        { src: "../photos/bethlen/bethlen_004.jpg", width: 1920, height: 1280 },
        { src: "../photos/bethlen/bethlen_005.jpg", width: 1920, height: 1280 },
        { src: "../photos/bethlen/bethlen_006.jpg", width: 1920, height: 1280 }
      ];

    const mapData = { 
        'name' : 'Bethlen utcai mélygarázs',
        'address' : '4026 Debrecen, Bethlen u. 10.',
        'lng' : 21.620543420004115,
        'lat' : 47.532098386660415
    }

    useEffect(() => {
        const fetchRequest = async () => {
            const options = {
                url:  `./occupancy_data.php`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseOccupancyData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedOccupancy(responseOccupancyData);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest]);

        useEffect(() => {
            const fetchRequest = async () => {
                const options = {
                    url:  `./get_date.php`,
                    method: 'GET',
                    body: null,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
                try {
                const responseData = await sendRequest(
                    options.url,
                    options.method,
                    options.body,
                    options.headers
                );
                setLoadedTime(responseData);
                } catch (err) {}
            };
            fetchRequest();
            }, [sendRequest]);

    useEffect(() => {
        const fetchRequest = async () => {
            const options = {
                url:  `./occupancy_data.php`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseOccupancyData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedOccupancy(responseOccupancyData);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest]);

        useEffect(() => {
            const fetchRequest = async () => {
                const options = {
                    url:  `./get_date.php`,
                    method: 'GET',
                    body: null,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
                try {
                const responseData = await sendRequest(
                    options.url,
                    options.method,
                    options.body,
                    options.headers
                );
                setLoadedTime(responseData);
                } catch (err) {}
            };
            fetchRequest();
            }, [sendRequest]);

    return (
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            <div className="mg-orange"></div>
            <div className="box-title">{mapData.name}</div>
            <div className='mg-box'>
                <div className='mg-left-box'>
                    <div className='mg-general'>
                        <div className="mg-title">Információk</div>
                        <div>{mapData.address}</div>
                        <div>Szabad férőhelyek: {loadedOccupancy && (loadedOccupancy.areas[1].capacity - loadedOccupancy.areas[1].occupied)} / {loadedOccupancy && loadedOccupancy.areas[1].capacity} (frissítve: {loadedTime && loadedTime.lastModified})</div>
                        <div>Parkolási tarifa: 480Ft/óra</div>
                        <div>Fizetési lehetőségek: Rollet, Bankkártya, Készpénz </div>
                        <div>Magasság korlátozás: 1,9m</div>
                    </div>
                    <div className='mg-desc'>
                    <div className='mg-title'>Megközelíthetőség</div>
                    <div className='mg-text'>A Bethlen utcai mélygarázs a Mediterrán Teraszház alatt található. A be-, és a kihajtás egy irányból, a Bethlen utca felől történik. Behajtani mindkét irányból, a Bethlen u. 10-12. sz. alatt található bejáraton, kihajtani pedig a Bethlen utca 6-8. sz. alatt található kapun keresztül lehet. A garázst csak jobbra, a Hatvan utca irányába hagyhatják el az autósok.</div>
                </div>
                <div className='mg-map'>
                    <MapMg mapData={mapData}/>
                </div>
                </div>
                <div className='mg-right-box'>
                    
                    <div className='mg-photos'>
                      
                      <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                      <Lightbox
                          slides={photos}
                          open={index >= 0}
                          index={index}
                          close={() => setIndex(-1)}
                          // enable optional lightbox plugins
                          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                      />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Bethlen;