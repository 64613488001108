import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from '../components/LoadingSpinner';
import MapMg from '../components/MapMg';
import './Sas.css';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Halkoz = () => {

    const [loadedOccupancy, setLoadedOccupancy] = useState();
    const [loadedTime, setLoadedTime] = useState();
    const { isLoading, sendRequest } = useHttpClient();
    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "../photos/halkoz/halkoz_001.jpg", width: 1920, height: 1280 },
        { src: "../photos/halkoz/halkoz_002.jpg", width: 1920, height: 1280 },
        { src: "../photos/halkoz/halkoz_003.jpg", width: 1920, height: 1280 },
        { src: "../photos/halkoz/halkoz_004.jpg", width: 4712, height: 1280 },
        { src: "../photos/halkoz/halkoz_005.jpg", width: 1920, height: 1280 },
        { src: "../photos/halkoz/halkoz_006.jpg", width: 1920, height: 1280 }
      ];

    const mapData = { 
        'name' : 'Hal köz mélygarázs',
        'address' : '4025 Debrecen, Simonffy u. 4–6.',
        'lng' : 21.6231732152905,
        'lat' : 47.5291136400494
    }

    useEffect(() => {
        const fetchRequest = async () => {
            const options = {
                url:  `./occupancy_data.php`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseOccupancyData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedOccupancy(responseOccupancyData);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest]);

        useEffect(() => {
            const fetchRequest = async () => {
                const options = {
                    url:  `./get_date.php`,
                    method: 'GET',
                    body: null,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
                try {
                const responseData = await sendRequest(
                    options.url,
                    options.method,
                    options.body,
                    options.headers
                );
                setLoadedTime(responseData);
                } catch (err) {}
            };
            fetchRequest();
            }, [sendRequest]);

    return (
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            <div className="mg-orange"></div>
            <div className="box-title">{mapData.name}</div>
            <div className='mg-box'>
                <div className='mg-left-box'>
                    <div className='mg-general'>
                        <div className="mg-title">Információk</div>
                        <div>{mapData.address}</div>
                        <div>Szabad férőhelyek: {loadedOccupancy && (loadedOccupancy.areas[3].capacity - loadedOccupancy.areas[3].occupied)} / {loadedOccupancy && loadedOccupancy.areas[3].capacity} (frissítve: {loadedTime && loadedTime.lastModified})</div>
                        <div>Parkolási tarifa: 480Ft/óra</div>
                        <div>Fizetési lehetőségek: Rollet, Bankkártya, Készpénz </div>
                        <div>Magasság korlátozás: 1,85m / 1,8m</div>
                    </div>
                    <div className='mg-desc'>
                    <div className='mg-title'>Megközelíthetőség</div>
                    <div className='mg-text'>A Hal köz mélygarázs a Széchenyi utcán a Régi Posta Étterem épületével szemben  található. Megközelíteni csak a Simonffy utca felől lehet, jobbra kanyarodással. Ezen a kapun a be-, és a kihajtás is engedélyezett. A mélygarázsban parkoló autósok kihajtáshoz a Széchenyi utcára nyíló kaput is használhatják, ahol az utca forgalmi rendje miatt csak balra kanyarodva hagyhatják el a garázst. Itt, jelzőlámpa irányítja a forgalmat.</div>
                </div>
                <div className='mg-map'>
                    <MapMg mapData={mapData}/>
                </div>
                </div>
                <div className='mg-right-box'>
                    
                    <div className='mg-photos'>
                      
                      <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                      <Lightbox
                          slides={photos}
                          open={index >= 0}
                          index={index}
                          close={() => setIndex(-1)}
                          // enable optional lightbox plugins
                          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                      />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Halkoz;